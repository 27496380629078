.services-page-custom-masthead {
    overflow         : visible;
    padding   : 150px 0 80px;
    min-height       : 430px;

    .headline {
        &.hdr-xxl {
            @include display-font-med(58px);
            line-height: clamp(60px, 6vw, 110px);
            font-size  : clamp(50px, 6vw, 100px);
        }
    }

    @include media-breakpoint-up(xl) {
        min-height    : 550px;
        padding-bottom: 100px;
    }

    @include media-breakpoint-up(xxl) {
        min-height: 650px;
    }

    &+.main-content .marquee-horizontal-scroll-panel {
        margin-top: -120px;
    }
}
