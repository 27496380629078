// Branding, Marketing, Creative
.services-bmc-container {
    position: relative;
}

.services-bmc-wrapper {
    z-index : 1;
    position: relative;
}

.services-bmc-item {
    margin: 40px 0;

    @include media-breakpoint-up(md) {
        margin: 0 0 130px;
    }

    h2 {
        @include fontSize(32px);

        @include media-breakpoint-up(md) {
            @include fontSize(52px);
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            position: relative;
            padding-left: 1.5rem;
            @include fontSize(14px);
            line-height: 19px;
            padding-top: 1px;
            padding-bottom: 1px;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
                height: 20px;
                background-image: url('/static/img/icons/checkmark.svg');
                background-size: contain;
                background-repeat: no-repeat;
                
            }
        }
    }

    picture {
        // @include media-breakpoint-up(md) {
        //     margin-top: 82px;
        // }
    }

}

.linear-border {
    background: linear-gradient(90deg, #FBEF00 0%, #02B159 49%, #00BBE3 100%);
    padding: 2px;
    border-radius: 20px;

    @include media-breakpoint-up(md) {
        border-radius: 100px;
        
    }
}

section.partnerships-block {
    border-radius: 20px;
    overflow     : hidden;
  
    

    // &:before {
    //     content: '';
    //     position: absolute;
    //     top     : 0;
    //     left    : 0;
    //     right   : 0;
    //     bottom  : 0;
    //     background: linear-gradient(90deg, #FBEF00 0%, #02B159 49%, #00BBE3 100%);
    //     margin: -20px;
    //     border-radius: inherit;
    //     z-index: -1;
    // }


    @include media-breakpoint-up(md) {
        border-radius: 100px;
        
    }

    .container {
        .partnerships-block-content {
            padding: 40px 20px;

            @include media-breakpoint-up(md) {
                padding: 60px 70px;
            }

            @include media-breakpoint-up(xl) {
                padding: 130px 140px;
            }

            h2 {
                @include fontSize(32px);

                @include media-breakpoint-up(md) {
                    @include fontSize(42px);
                }

                @include media-breakpoint-up(xl) {
                    @include fontSize(52px);
                }
            }
            
    
            .partnership-strip {
                // padding: 0;
                // margin: 0;
                list-style: none;
                position: relative;
                background: 0;
                margin-top: 20px;
                height: 100px;

                @include media-breakpoint-up(md) {
                    height    : 100px;
                    margin-top: 40px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    background: linear-gradient(to right, 
                        rgba(30, 33, 38, 1),        
                        rgba(30, 33, 38, 0) 10%,   
                        rgba(30, 33, 38, 0) 90%,    
                        rgba(30, 33, 38, 1) 100%    
                    );
                    width: 100%;
                    height: 100%;
                }
    
                li {
                    margin: 0;
                    padding: 0;
                }

                .marquee__part {
                    align-items: center;
                    
                    picture {
                        padding: 0 30px;
                    }
                }
            }
            
        }
    }

    
}