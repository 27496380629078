html,
body {
    // Trying this for now, will re-introduce if overflows are prevalent.
    // overscroll-behavior: initial;
}

html {
    max-width: 100vw;
    background-color: $raisin;
}

body {
    overflow-x: hidden;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-weight: 400; // Gotham 'Book'
    @include fontSize(17px);
    line-height: 29px;
    color: $black;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    background-color: $white;

    &.preloading {

        position: fixed;
        height: 100%;
        overflow: hidden;

        .main-nav {
            visibility: hidden;
        }

        .preloader-section {
            z-index: 1;
            transition: 1.25s all ease-in;
            opacity: 1;
        }
    }



    p {
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(md) {
            @include fontSize(14px);
            line-height: 21px;
            margin-bottom: 20px;
        }

        a {
            font-weight: 500;

            &:link,
            &:visited,
            &:focus,
            &:hover,
            &:active {
                text-decoration: underline;
                color: black;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    ul,
    ol,
    dl,
    dd {
        margin-bottom: 1.5rem;
        padding-left: 1rem;

        @include media-breakpoint-down(md) {
            @include fontSize(14px);
            line-height: 21px;
            padding-left: 1.1rem;
        }
    }

    ol {
        padding-left: 1.25rem;
    }

    iframe,
    embed,
    video {
        border: 0;
    }



    section {
        overflow: hidden;
    }

    img,
    svg {
        backface-visibility: hidden;
    }

    b,
    strong {
        font-weight: bold;
    }

    blockquote {
        q {

            &:before,
            &:after,
            p {
                display: inline;
            }
        }
    }
}

.scroll-behavior-auto {
    scroll-behavior: auto;
}

.scroll-behavior-smooth {
    scroll-behavior: smooth;
}

#not-smooth {
    overscroll-behavior-y: hidden;
    scroll-behavior: auto;
}

#smooth-scroll-wrapper,
#not-smooth {
    background-color: $raisin;
}

#smooth-scroll-content {
    background-color: $white;
}
