.services {

    .scrolling-marquee {
        //@include magicBackgroundGradient2;

        .marquee__part .value {
            color: $white;
        }
    }
}


.marquee-horizontal-scroll-panel {
    padding-top: 120px;
    margin-bottom: -120px;
}

.services-page-custom-cta {
    padding-top: 240px;

    &.cc-contact-panel-block {
        .container.inner {
            padding-top: 120px;
        }
    }
}

.image-on-top-content-block .content {
    @include fontSize(18px);
    line-height: fontSize(32px);
}