@keyframes fadeIn {
    0% {
        opacity  : 0;
        transform: translateY(10px);
    }

    50% {
        opacity  : 0;
        transform: translateY(5px);
    }

    100% {
        opacity  : 1;
        transform: translateY(0px);
    }
}

.main-nav {
    position: absolute;
    z-index : 2;

    .logo {
        z-index: 12;
    }


    .logo-wrap {
        padding-top    : 18px;
        height         : 100%;
        display        : flex;
        align-items    : flex-start;
        justify-content: flex-start;

        @include media-breakpoint-up(md) {
            padding-top : 40px;
            padding-left: 40px;
        }
    }

    .nav-inner {
        max-width: 100%;
    }
}

.hp-link {
    #main-logo {
        position  : relative;
        transition: transform .2s ease-in;
    }

    &:hover #main-logo {
        transform: scale(1.05);
    }
}

body.nav-expanded {
    overflow: hidden;

    #main-logo {
        opacity   : 0;
        animation : fadeIn .75s forwards .1s;
        position  : fixed;
        margin-top: 4px;
    }

    .over-masthead #main-logo {
        opacity  : 1;
        animation: none;
    }

    .nav-inner {
        height: 80px;
    }

    .nav-inner,
    .nav-list-container {
        z-index: 1001;
    }

    .main-content {
        position: relative;
        z-index : 0;
    }

    // Make sure close X is on top of everything when open
    .nav-inner {
        z-index: 1002;
    }

    .fancy-menu {
        z-index: 1001;
    }
}

.nav-list-container {
    background-color: $raisin;

    // Fingaprint bg
    @include fingerPrint();

    background-image: url('/static/img/svg/fingerprint-nav.svg');
}

.active-clip-animation {
    position         : absolute;
    -webkit-clip-path: circle(0% at 100% 0%);
    clip-path        : circle(0% at 100% 0%);
    transition       : clip-path .4s ease-out;

    &.reveal {
        position         : fixed;
        transition       : clip-path .3s ease-in;
        -webkit-clip-path: circle(200% at 100% 0%);
        clip-path        : circle(200% at 100% 0%);
    }
}


.nav-list-container.active-clip-animation {
    overflow-y: scroll;
    top       : 0;
    left      : 0;
    height    : 100vh;
    width     : 100%;
    max-width : 100vw;
    z-index   : -1;
}

.nav-inner {
    z-index: 1002;
}

.over-dark {
    #nav-icon {
        &:after {
            background: rgba($black, .4);
        }
    }

    #nav-icon span {
        background: $white;
    }
}

.over-light {
    #nav-icon {
        &:after {
            background: rgba($white, .4);
        }
    }

    #nav-icon span {
        background: $black;
    }
}

.over-masthead {

    #nav-icon span {
        background: $turbo;
    }
}

.home-page .over-masthead {
    #nav-icon {
        &:after {
            background: transparent;
        }
    }

    #nav-icon span {
        background: $black;
    }
}

#main-logo {
    position: absolute;
    filter  : drop-shadow(1px 2px 5px rgb(0 0 0 / 0.4));
}

// Safari LOL
@supports (hanging-punctuation: first) and (-webkit-appearance: none) {
    .main-nav {
        overflow: hidden;

        body.nav-expanded .main-nav {
            overflow: visible;
        }
    }
}
