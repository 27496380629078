.scrolling-marquee {
    background-color: $turbo;
    @include magicBackgroundGradient();
    background-size    : 800% 200%;
    animation          : gradient 50s linear infinite;
    animation-direction: alternate;
    position           : relative;
    overflow           : hidden;
    height             : 60px;
    display            : flex;
    margin-top         : 40px;

    @include media-breakpoint-up(md) {
        height    : 115px;
        margin-top: 0;
    }

    .marquee_ {
        &_inner {
            -webkit-font-smoothing: antialiased;
            width                 : fit-content;
            display               : flex;
            flex                  : auto;
            flex-direction        : row;
        }

        &_part {
            display    : flex;
            flex-shrink: 0;
            font-smooth: always;
        }
    }
}

.value {
    margin         : 0;
    padding        : 0;
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-bottom  : 0 !important;

    &:after {
        content: '\2022';
        padding: 0 10px;

        @include media-breakpoint-up(md) {
            padding: 0 20px;
        }
    }
}

.landing-logo-scrolling-marquee {
    position  : relative;
    overflow  : hidden;
    height    : 60px;
    display   : flex;
    margin-top: 40px;

    &.tiles-marquee {
        background-color: transparent;
        background      : transparent;
        animation       : none;
        margin-top      : 0;
        height          : fit-content;

        .marquee__part {
            display    : inline-flex;
            grid-gap   : 1.5rem;
            align-items: center;
            height     : fit-content;
            width      : fit-content;
        }

        .scroll-item {
            flex            : 1 0 clamp(12.5rem, 10.3261rem + 10.8696vw, 270px);
            position        : relative;
            display         : flex;
            align-items     : center;
            justify-content : center;
            background-color: $whitesand;
            border-radius   : 0;
            overflow        : hidden;
            height          : 160px;

            @include media-breakpoint-up(md) {
                height: 160px;
            }

            @include media-breakpoint-up(lg) {
                height: 180px;
            }

            @include media-breakpoint-up(xl) {
                height: 200px;
            }

            img {
                width  : 78%;
                height : fit-content;
                padding: 1rem;
                filter : grayscale(1);
            }
        }

        @include media-breakpoint-up(md) {
            height    : fit-content;
            margin-top: 0;
        }
    }

    @include media-breakpoint-up(md) {
        height    : 115px;
        margin-top: 0;
    }

    .marquee_ {
        &_inner {
            -webkit-font-smoothing: antialiased;
            width                 : fit-content;
            display               : flex;
            flex                  : 1 1 auto;
            flex-direction        : row;
            grid-gap              : 1.5rem;
            justify-content       : flex-start;
        }

        &_part {
            display    : flex;
            flex-shrink: 0;
            font-smooth: always;
        }
    }
}