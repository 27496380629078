.rounded {
    border-radius: $border-radius !important;
}

.rounded-20 {
    border-radius: 20px;
    overflow     : hidden;
}

.rounded-40 {
    @include smallRadiusCorner();
    overflow: hidden;
}

.rounded-80 {
    border-radius: 80px;
    overflow     : hidden;
}

.rounded-100 {
    border-radius: 100px;
    overflow     : hidden;
}

.rounded-bottom {
    border-bottom-right-radius: $border-radius !important;
    border-bottom-left-radius : $border-radius !important;
}

.rounded-top {
    border-top-right-radius: $border-radius !important;
    border-top-left-radius : $border-radius !important;
}

.rounded-left {
    border-top-left-radius   : $border-radius !important;
    border-bottom-left-radius: $border-radius !important;
}

.rounded-right {
    border-top-right-radius   : $border-radius !important;
    border-bottom-right-radius: $border-radius !important;
}

.btn,
.form-control {
    border-radius: $border-radius;
}
